.table-0 {
    width: 100%;
    padding: 0;
    margin-top: 5mm;
    /* border-bottom: 1px solid gray; */
    /* border-left: 1px solid gray; */
}

.table-0 th,
.table-0 td {
    font-size: 0.65rem;
    padding-inline: 0.19rem;
}

.table-0 th {
    border-block: 1px solid gray;
    background-color: #434343;
    padding: 0.23rem;
    color: white;
}

.table-0 tr:last-child {
    border-bottom: 0.01rem solid gray;
}

.table-0 tr:nth-child(even) {
    background-color: rgb(230, 230, 230);
}

.table-0 th {
    font-weight: bold;
}

.table-0 td:first-child,
.table-0 th:first-child {
    border-left: solid 1px gray;
}

.table-0 td:last-child,
.table-0 th:last-child {
    border-right: solid 1px gray;
}