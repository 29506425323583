// @import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import '~antd/es/style/themes/default.less';

.ant-input {
    border-radius: 5px !important;
}

.ant-picker {
    border-radius: 5px !important;
}

.ant-input-number {
    border-radius: 5px !important;
}

/* Width and height of the scrollbar */
::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
}

/* Track styles */
::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Set the background color of the track */
}

/* Handle styles */
::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Set the color of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #555 !important;
    /* Set the color of the scrollbar handle when hovering */
}
@primary-color: #820014;@theme-teks: #820014;