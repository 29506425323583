.logo {
    height: 64px;
    padding: 8px;
    background: white;
    border-bottom: 1px solid gray;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.logo-md {
    height: 64px;
    padding: 8px;
    background: rgb(0 21 41);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.site-layout-sub-header-background {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px !important;
}

.sider-background {
    background-color: red !important;
}

.trigger {
    margin-inline: 16px;
    font-size: 24px;
    cursor: pointer;
    margin-top: -8px;
}

.site-layout-background {
    background: #fff;
}

[data-theme="dark"] .site-layout-sub-header-background {
    background: #141414;
}

.user-profile-text {
    line-height: 24px !important;
    text-align: right;
    text-transform: uppercase;
    color: #e9e9e9 !important;
}

.user-profile-status {
    font-size: x-small;
}

.user-profile-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
}

.user-profile-text-wrapper {
    display: flex;
    flex-direction: column;
    margin-inline: 16px;
}